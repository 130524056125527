<template>
  <eagle-tree
    :tree-key="treeKey"
  ></eagle-tree>
</template>

<script lang="babel" type="text/babel">
import treeMixin from '@/components/tree/mixins/treeMixin'
import treeConfig from './productCategoryTreeConfig'
export default {
  mixins: [treeMixin],
  data: () => ({
    treeKey: 'product-category',
    meta: {},
  }),
  methods: {
    async getTreeApi() {
      return await this.$api.collection.productCategoryApi.tree()
    },
    async createApi(id, data) {
      return await this.$api.collection.productCategoryApi.create(id, data)
    },
    async updateApi(id, data) {
      return await this.$api.collection.productCategoryApi.update(id, data)
    },
    async moveApi(from ,to, mode) {
      return await this.$api.collection.productCategoryApi.move(from, to, mode)
    },
    async deleteApi(id) {
      return await this.$api.collection.productCategoryApi.delete(id)
    },
    getTreeConfig() {
      return treeConfig
    },
  },
  computed: {
    productCategoryMaxDepth() {
      return this.$store.getters['base/productCategoryMaxDepth']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
